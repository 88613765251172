import React from "react";
import styled from "@emotion/styled";
import { useS3, useFileQuery } from "./hooks";
import { ReactComponent as FileIconBlue } from "../../assets/FileIconBlue.svg";
import { BarLoader } from "react-spinners";
import { ReactComponent as CheckMarkWhite } from "../../assets/CheckMarkWhite.svg";
import { ReactComponent as XWhite } from "../../assets/XWhite.svg";
import { limitString, removePrefix } from "../../Util";
import { COLORS } from "../../Theme";

const fileSize = "3.2 MB";

export default function UploadTool({
  file,
  setFile,
  fileError,
  setFileError,
  onUploadComplete,
}: {
  file: any;
  setFile: any;
  fileError: any;
  setFileError: any;
  onUploadComplete?: () => void;
}) {
  // upload to s3 and get file url
  const { uploadToS3, fileUrl, fileUploading, uploadError, uploadStatus } = useS3();

  // get file status
  const { existingFile } = useFileQuery();

  const onCancel = () => {
    setFile()
  };

  const onUpload = async (file: any) => {
    await uploadToS3(file);
    onUploadComplete && onUploadComplete();
  }

  return (
    <Container>
      <div>
        <div>
          {fileUploading ? (
            <div className="file-ready">UploadingFile</div>
          ) : uploadStatus === "uploaded" ? (
            <div className="file-ready">File Uploaded</div>
          ) : file ? (
            <div className="file-ready">File Ready to Upload</div>
          ) : fileError ? (
            <div className="file-error">File Error: {fileError}</div>
          ) : null}
        </div>
        {file && (
          <FileRow>
            <FileIconBlue width={40} height={40} />
            <div className="file-info">
              <p className="file-name">{limitString(removePrefix(file.name), 30)}</p>
              {fileUploading ? (
                <div className="upload-bar">
                  <BarLoader color={"#123abc"} loading={fileUploading} />
                </div>
              ) : fileUrl ? (
                <div
                  className="upload-bar"
                  style={{ backgroundColor: "#a4edc3" }}
                />
              ) : (
                <div
                  className="upload-bar"
                  style={{ backgroundColor: "#ebebeb" }}
                />
              )}
              <p className="file-size">{fileSize}</p>
            </div>
            {fileUrl ? (
              <GreenCircle>
                <CheckMarkWhite />
              </GreenCircle>
            ) : (
              <CancelButton onClick={onCancel}>
                <XWhite />
              </CancelButton>
            )}
          </FileRow>
        )}
        {existingFile ? (
          <div>Only one upload allowed per 24 hours</div>
        ) : (
          !fileUploading &&
          !fileError &&
          file &&
          uploadStatus !== "uploaded" && (
            <button className="upload-button" onClick={() => onUpload(file)}>
              Upload
            </button>
          )
        )}
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 350px;
  .file-ready {
    color: #a4edc3;
    font-size: 14px;
    font-weight: 500;
    font-family: "Gotham Bold";
    margin-top: 15px;
    margin-left: 10px;
  }

  .file-error {
    color: red;
    font-size: 14px;
    font-weight: 500;
    font-family: "Gotham Bold";
    margin-top: 15px;
    margin-left: 10px;
  }

  .upload-button {
    margin-left: 50px;
    background-color: ${COLORS.PRIMARY};
    color: #fff;
    border-radius: 5px;
    border: none;
    padding: 5px 20px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.PRIMARY_HOVER};
    }
  }
`;

const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  margin: 20px 0;
  width: 100%;

  .file-info {
    width: 100%;
    padding: 0 10px;
  }

  .file-name {
    color: #000;
    margin: 0;
  }

  .file-size {
    color: #b2b2b2;
    margin: 0;
  }

  .upload-bar {
    width: 100%;
    height: 5px;
    background-color: #ebebeb;
    border-radius: 5px;
    margin: 10px 0;
  }
`;

const CancelButton = styled.button`
  background-color: #d8d8d8;
  border: none;
  border-radius: 50px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const GreenCircle = styled.div`
  background-color: #a4edc3;
  border: none;
  border-radius: 100px;
  padding: 5px 6px;
`;
