import { lazy, useContext, useEffect } from "react";

import PrivateRoute from "./Auth/PrivateRoute";
import Login from "./Auth/Login/Login";
import AuthContext from "./Auth/AuthContext";

import { Background } from "./GlobalComponents";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

// launch darkly
import { withLDConsumer, useLDClient } from "launchdarkly-react-client-sdk";
import { RosterTeams } from "./Views";

// Lazy imports
const Dashboard = lazy(() => import("./Views/Dashboard"));
const COADFileDrop = lazy(() => import("./Views/COADFileDrop"));
const RosterList = lazy(
  () => import("./Views/Roster/RosterEmployees/RosterList")
);
const RosterSingleEmployee = lazy(
  () => import("./Views/Roster/RosterEmployees/RosterSingleEmployee")
);
// const RosterTeams = lazy(() => import("./Views/Roster/RosterTeams"));
const ReviewChangeOrder = lazy(() => import("./Views/ReviewChangeOrder"));
const SingleTeamView = lazy(
  () => import("./Views/Roster/RosterTeams/SingleTeamView")
);


const BrowserRouter = ({ flags }: { flags?: any }) => {
  const ldClient = useLDClient();
  const { authProviderLocal, isAuthenticated, azureAccount } = useContext(AuthContext);


  // identify user for launch darkly
  if (azureAccount) {
      const context = { key: azureAccount.account.userName };
      ldClient && ldClient.identify(context, undefined);
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Background />}>
        <Route
          path="/login"
          element={
            <Login login={authProviderLocal} authCheck={isAuthenticated} />
          }
        />
        <Route
          index
          element={
            <PrivateRoute requiredPermissions="roster">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/coadfiledrop"
          element={
            <PrivateRoute featureFlag={flags.coadFileUploader}>
              <COADFileDrop />
            </PrivateRoute>
          }
        />
        <Route
          path="/roster/employees"
          element={
            <PrivateRoute
              requiredPermissions="roster"
              featureFlag={flags.rosterEmployees}
            >
              <RosterList />
            </PrivateRoute>
          }
        >
          <Route
            path="/roster/employees/:id"
            element={
              <PrivateRoute
                requiredPermissions="roster"
                featureFlag={flags.rosterEmployees}
              >
                <RosterSingleEmployee />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
          path="/roster/teams"
          element={
            <PrivateRoute
              requiredPermissions="teams"
              featureFlag={flags.rosterTeams}
            >
              <RosterTeams />
            </PrivateRoute>
          }
        />
        <Route
          path="/roster/teams/:team_id"
          element={
            <PrivateRoute
              requiredPermissions="teams"
              featureFlag={flags.rosterTeams}
            >
              <SingleTeamView />
            </PrivateRoute>
          }
        />
        <Route
          path="/review-change-order/:id"
          element={
            <PrivateRoute>
              <ReviewChangeOrder />
            </PrivateRoute>
          }
        />
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

// @ts-ignore
export default withLDConsumer()(BrowserRouter);