import "./style.css";
import { useState, useEffect } from "react";
import {
  animated,
  useSpring,
  config,
  useSpringRef,
  useChain,
} from "react-spring";

function AnimatedCheckbox({justAnimation = false}: { justAnimation?: boolean }) {
  const [isChecked, setIsChecked] = useState(false);
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    transform: isChecked ? "scale(1)" : "scale(0.8)",
    backgroundColor: isChecked ? "#a4edc3" : "#fff",
    borderColor: isChecked ? "#a4edc3" : "#ddd",
    config: config.gentle,
    ref: checkboxAnimationRef,
  });

  const [checkmarkLength, setCheckmarkLength] = useState(null);

  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef,
  });

  useChain(
    isChecked
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0.5]
  );

  useEffect(() => {
    justAnimation && setIsChecked(true);
  }, []);

  return (
    <label>
      <input
        type="checkbox"
        className="animated-checkbox-input"
        onChange={() => {
          !justAnimation && setIsChecked(!isChecked);
        }}
      />
      <animated.svg
        style={checkboxAnimationStyle}
        className={`animated-checkbox ${isChecked ? "checkbox--active" : ""}`}
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
        viewBox="0 0 15 11"
        fill="none"
      >
        <animated.path
          d="M1 4.5L5 9L14 1"
          strokeWidth="2"
          stroke="#fff"
          ref={(ref: any) => {
            if (ref) {
              setCheckmarkLength(ref.getTotalLength());
            }
          }}
          // @ts-ignore
          strokeDasharray={checkmarkLength}
          // @ts-ignore
          strokeDashoffset={checkmarkAnimationStyle.x}
        />
      </animated.svg>
    </label>
  );
}

export default AnimatedCheckbox;
