import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import FilterSelect from "../Components/FilterSelect";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchEmployees, fetchOptions, fetchTeamLeads } from "../../api";
import { AsyncAutoComplete } from "../../../GlobalComponents";
import { Input } from "../../../GlobalComponents";
import { postTeam } from "../../api";
import { useParams } from "react-router-dom";
import { AnimatedCheckbox } from "../../../GlobalComponents";
import { MoonLoader } from "react-spinners";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 700,
  height: 550,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  color: COLORS.PRIMARY,
  padding: "30px 0",
};

export default function CreateTeamModal({
  open,
  setOpen,
  team,
}: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  team?: any;
}) {
  const queryClient = useQueryClient();
  const [teamName, setTeamName] = useState(team?.team_name || "");
  const [teamLead, setTeamLead] = useState<Employee | null>(team?.team_lead || null);
  const [teamMarket, setTeamMarket] = useState(team?.market || "");
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  useEffect(() => {
    console.log("team lead update", teamLead);
  },[teamLead])

  useEffect(() => {
    console.log("team market update", teamMarket);
  },[teamMarket])

  const {
    data: optionsData,
    error: optionsError,
    isLoading: optionsLoading,
    refetch: optionsRefetch,
  } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  const { mutate: teamMutate, isLoading: teamMutateLoading } = useMutation(
    postTeam,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["team"]);
        queryClient.invalidateQueries(["teams"]);
        queryClient.invalidateQueries(["employees_by_team_id"]);
        setShowSuccess(true);
      },
      onError: () => {
        setError("There was an error submitting your request. Please try again later.");
      },
    }
  );

  useEffect(() => {
    console.log("options data", optionsData);
  },[optionsData])

  const onSave = () => {
    if(team) {
      console.log("TEAM", team)
      // edit team
      let body: TeamEditBody = {
        team_id: team.id,
      }
      if(teamName) {
        body = {
          ...body,
          new_team_name: teamName,
        }
      }
      if(teamLead) {
        body = {
          ...body,
          new_team_leader_mid: teamLead?.Momentum_id || "",
        }
      }

      // mutation goes here
      teamMutate({
        route: "/edit_team",
        body: body
      })

    } else {
      // create team

      if (!teamName) {
        setError("Please enter a team name");
        return;
      }
      if (!teamLead) {
        setError("Please select a team lead");
        return;
      }
      if (!teamMarket) {
        setError("Please select a market");
        return;
      }
      setError(null);
      console.log("team market", teamMarket)
      const body: TeamCreateBody = {
        team_leader_mid: teamLead?.Momentum_id || "",
        office_code: teamMarket,
        team_name: teamName,
        dept_grouping: "SALES",
      };
      
      // mutation goes here
      teamMutate({
        route: "/create_team",
        body: body
      });

    }
  };

  const onClose = () => {
    setShowSuccess(false);
    setTeamName(team?.team_name || "");
    setTeamLead(team?.team_lead || null);
    setTeamMarket(team?.market || "");
    setError(null);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {
          teamMutateLoading ?
          <InnerContainer>
            <Typography variant="h5" component="h2">
              Just a moment...
            </Typography>
            <div className="checkbox-container">
              <MoonLoader size={80} color={COLORS.PRIMARY} />
            </div>
          </InnerContainer>

          : showSuccess ?
            <InnerContainer style={{width: "100%", display: "flex", justifyContent: "center"}}>
              <Typography variant="h5" component="h2">
                {`Team Successfully ${team ? "Updated" : "Created"}!`}
              </Typography>
              <div className="checkbox-container">
                <AnimatedCheckbox justAnimation/>
              </div>
            </InnerContainer>
          :
          <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {team ? `Edit: ${team.team_name}` : "Create New Team"}
            </Typography>
            <p style={{color: "red", fontFamily: "Lato", fontSize: 14}}>{error}</p>
            <ActionsContainer>
              <div className="section">
                <p className="section-title">{team ? "Change" : ""} Team Name</p>
                <Input
                  width={270}
                  height={40}
                  placeholder="Enter Team Name"
                  value={teamName}
                  onChange={(e) => setTeamName(e)}
                />
              </div>
              <div className="section">
                <p className="section-title">Team Lead</p>
                <div className="info">
                  <p style={{ fontFamily: "Lato" }}>
                    Team Lead Name: {teamLead?.Name || team?.team_leader_name}
                  </p>
                  <p style={{ fontFamily: "Lato" }}>
                    Team Lead Momentum ID: {teamLead?.Momentum_id || team?.team_leader_mid}
                  </p>
                </div>
                <AsyncAutoComplete
                  width={265}
                  onSelect={setTeamLead}
                  placeholder="Enter Team Lead Name"
                />
              </div>
              {!team && (
                <div className="section">
                  <p className="section-title">Market</p>
                  <FilterSelect
                    width={265}
                    height={40}
                    itemKey="value"
                    label="Select Market"
                    options={optionsData?.markets || []}
                    value={optionsData?.markets.find((m) => m.value === teamMarket)}
                    onSelect={(e: string) => {
                      console.log("EEEEE", e);
                      setTeamMarket(e);
                    }}
                  />
                </div>
              )}
            </ActionsContainer>
            <SaveContainer>
              <Button
                onClick={() => {
                  onClose();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button onClick={onSave}>Save</Button>
            </SaveContainer>
          </div>
        }
      </Box>
    </Modal>
  );
}

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  height: 70%;
  width: 100%;
  align-items: center;

  .team-name-input {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid gray;
    padding: 3px 7.5px;
    margin-top: 5px;
  }
  p {
    font-family: "Gotham Bold"
  }

  .section {
    width: 80%;
    margin-bottom: 20px;
  }

  .section-title {
    border-bottom: 1px solid #d5d5d5;
    height: 20px;
  }

  .info {
    color: #000;
    font-size: 14px;
    margin-bottom: 30px;
  }
`;

const SaveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkbox-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
  }
`;