import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { COLORS } from "../../../Theme";
import { useQuery } from "@tanstack/react-query";
import { fetchEmployees, fetchOptions, fetchTeams } from "../../api";
import TeamsTable from "./TeamsTable";
import TeamQueue from "./SingleTeamView";
import CreateTeamModal from "./CreateTeamModal";
import { Button } from "../../../GlobalComponents";
import { TableContainer } from "../Components/Styled";

export default function RosterTeams() {
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [selectedTeamToEdit, setSelectedTeamToEdit] = useState<Team | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    console.log("selected team id", selectedTeamId);
  }, [selectedTeamId])

  
  
  // const { data: employeesData, error: employeesError, isLoading: employeesLoading, refetch: employeesRefetch } = useQuery({
  //   queryKey: ["employees"],
  //   queryFn: () => fetchEmployeesForTeams(),
  //   enabled: true,
  //   retry: false,
  // });
  // useEffect(() => {
  //   console.log("Employees Data", employeesData);
  // }, [employeesData])

  const { data: optionsData, error: optionsError, isLoading: optionsLoading, refetch: optionsRefetch } = useQuery({
    queryKey: ["options"],
    queryFn: () => fetchOptions(),
    enabled: true,
    retry: false,
  });

  const {
    data: teamsData,
    error: teamsError,
    isLoading: teamsLoading,
    refetch: teamsRefetch,
  } = useQuery({
    queryKey: ["teams"],
    queryFn: () => fetchTeams(),
    enabled: true,
    retry: false,
  });

  return (
    <Wrapper>
      <TableContainer>
        <div id="header">
          <div id="title">Search Team(s)</div>
          <Button
            onClick={() => {
              setSelectedTeamToEdit(null);
              setCreateModalOpen(true);
            }}
          >
            Create New Team
          </Button>
        </div>
        <TeamsTable
          rows={teamsData || []}
          onSelect={(teamId: number) => {
            console.log("team id", teamId);
            setSelectedTeamId(teamId);
          }}
          onEdit={(teamId: number) => {
            setSelectedTeamToEdit(
              teamsData?.find((team: Team) => team.id === teamId) ||
                null
            );
            setCreateModalOpen(true);
          }}
          loading={teamsLoading}
        />
      </TableContainer>
      <CreateTeamModal
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        team={selectedTeamToEdit}
      />
    </Wrapper>
  );
}


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  //   align-items: center;
  padding: 20px;
  color: #585858;

  #left-container {
    width: 30%;
  }

  #fileContainer {
  }
  p {
    color: #d5d5d5;
  }
  #title {
    color: ${COLORS.PRIMARY};
  }
  #header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
  }
  #header-tools-container {
    display: flex;
    width: 60%;
    justify-content: flex-end;
  }
  #table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #table-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
  #table-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
`;