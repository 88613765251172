import { useContext } from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { COLORS } from "../../Theme";
import { featureFlags } from "../../config";
import CollapsibleCard from "../CollapsibleCard";
import AuthContext from "../../Auth/AuthContext";
import { ReactComponent as SettingsIconGray } from "../../assets/SettingsIconGray.svg";
import { ReactComponent as SettingsIconBlue } from "../../assets/SettingsIconBlue.svg";
import { ReactComponent as HouseIconGray } from "../../assets/HouseIconGray.svg";
import { ReactComponent as HouseIconBlue } from "../../assets/HouseIconBlue.svg";
import { ReactComponent as RosterIconGray } from "../../assets/RosterIconGray.svg";
import { ReactComponent as RosterIconBlue } from "../../assets/RosterIconBlue.svg";
import { ReactComponent as FileIconGray } from "../../assets/FileIconGray.svg";
import { ReactComponent as FileIconBlue } from "../../assets/FileIconBlue.svg";
import { ReactComponent as EmployeeIconBlue } from "../../assets/EmployeeIconBlue.svg";
import { ReactComponent as EmployeeIconGray } from "../../assets/EmployeeIconGray.svg";
import GroupsIcon from "@mui/icons-material/Groups";

// launch darkly
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const SideBar = ({flags}: {flags?: any}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions } = useContext(AuthContext);

  console.log("PATH",location.pathname)
  console.log("FLAGS", flags)

  return (
    <Container>
      <NavContainer>
        <NavItem
          enabled={featureFlags.dashboard}
          active={location.pathname === "/"}
          onClick={() => navigate("")}
        >
          {location.pathname === "/" ? (
            <HouseIconBlue width={25} />
          ) : (
            <HouseIconGray width={25} />
          )}
          <p>Dashboard</p>
        </NavItem>
        {(flags.rosterEmployees || flags.rosterTeams) && (
          <CollapsibleCard
            title={"Roster"}
            open={location.pathname.includes("/roster")}
            headerComponent={
              <NavItem
                enabled
                active={location.pathname.includes("/roster")}
                onClick={() => {
                  flags.rosterEmployees
                    ? navigate("roster/employees")
                    : navigate("roster/teams");
                }}
              >
                {location.pathname.includes("/roster") ? (
                  <RosterIconBlue width={25} />
                ) : (
                  <RosterIconGray width={25} />
                )}
                <p>Roster</p>
              </NavItem>
            }
          >
            <div>
              {permissions?.includes("roster") && (
                <NavItem
                  enabled={flags.rosterEmployees}
                  active={location.pathname.includes("/roster/employees")}
                  onClick={() => navigate("roster/employees")}
                >
                  {location.pathname.includes("/roster/employees") ? (
                    <EmployeeIconBlue width={20} />
                  ) : (
                    <EmployeeIconGray width={20} />
                  )}
                  <p>Employees</p>
                </NavItem>
              )}
              {permissions?.includes("teams") && (
                <NavItem
                  enabled={flags.rosterTeams}
                  active={location.pathname.includes("/roster/teams")}
                  onClick={() => navigate("roster/teams")}
                >
                  {location.pathname.includes("/roster/teams") ? (
                    <GroupsIcon style={{color: COLORS.PRIMARY}}/>
                  ) : (
                    <GroupsIcon style={{color: "#D5D5D5"}}/>
                  )}
                  <p>Teams</p>
                </NavItem>
              )}
            </div>
          </CollapsibleCard>
        )}
        <NavItem
          enabled={featureFlags.coad_file_uploader}
          active={location.pathname === "/coadfiledrop"}
          onClick={() => {
            navigate("/coadfiledrop");
          }}
        >
          {location.pathname === "/coadfiledrop" ? (
            <FileIconBlue />
          ) : (
            <FileIconGray />
          )}
          <p>COAD File Uploader</p>
        </NavItem>
        <NavItem enabled={featureFlags.settings}>
          {location.pathname === "/settings" ? (
            <SettingsIconBlue />
          ) : (
            <SettingsIconGray />
          )}
          <p>Settings</p>
        </NavItem>
      </NavContainer>
      {featureFlags.announcements && (
        <AnnouncementWrapper>
          <AnnouncementContainer>
            <p>Create Announcement</p>
            <p>Send an announcement to your coworkers or a specific team</p>
            <button>Create Now</button>
          </AnnouncementContainer>
        </AnnouncementWrapper>
      )}
    </Container>
  );
}

export default withLDConsumer()(SideBar);

const Container = styled.div`
    position: relative;
    width: 20%;
    height: 100%;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
    
const NavContainer = styled.div`
    position: relative;
    width: 100%;
    height: 70%;
    padding-top: 20px;
`;

const NavItem = (props: any) => {

  console.log("ACTIVE ", props.active)

  const Item = styled.button`
    position: relative;
    width: 100%;
    // height: 75px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    background-color: ${props.active ? COLORS.BACKGROUND : "#fff"};

    p {
      position: relative;
      width: 100%;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      color: ${props.active ? COLORS.PRIMARY : "#D5D5D5"};
      padding: 25px 0 20px 0;
      margin: 0;
      margin-left: 10px;
      font-family: "Gotham Bold";
    }
  `;

  if(!props.enabled) return null;
  
  return (
    <Item onClick={props.onClick}>
      {props.children}
    </Item>
  )
};

const AnnouncementContainer = styled.div`
  position: relative;
  width: 70%;
  height: 70%;
  background-color: #f5f9fc;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: #585858;

    p {
        text-align: center;
    }
`;

const AnnouncementWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
