import { useState } from "react";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIA2VYLNHYV3YFZIT7M",
  secretAccessKey: "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",
  region: "us-east-1",
  signatureVersion: "v4",
});

export default function useS3() {
  const s3 = new AWS.S3();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<"processing" | "uploaded" | "error" | null>(null);

  const uploadToS3 = async (file: any) => {
    // get midnight of today in EST
    const midnight = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
    );

    // convert to ISO string
    const midnightString = midnight.toLocaleDateString().replace(/\//g, "_");
    
    setIsLoading(true);
    if (!file) {
      setIsLoading(false);
      return;
    }
    const params = {
      Bucket: "waldocoadv2",
      Key: `${midnightString}.${file.name}`,
      Body: file,
    };
    try {
      const { Location } = await s3.upload(params).promise();
      setFileUrl(Location);
      setStatus("uploaded");
      setIsLoading(false);
    }
    catch (err) {
      setError("Error uploading file");
      setIsLoading(false);
      console.log("error uploading to s3", err);
    }
  };

  return {
    fileUrl,
    uploadToS3,
    isLoading,
    error,
    fileUploading: isLoading,
    uploadError: error,
    uploadStatus: status,
  }
}
