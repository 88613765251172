import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchFilesFromS3 } from "../api";

export default function useGetFiles({ prefix, queryKey="fetchFilesFromS3" }: Partial<{ prefix?: string, queryKey: string }> = {}) {
  const { data, error, isLoading, refetch } = useQuery(
    [queryKey],
    () => fetchFilesFromS3(prefix),
    {
      enabled: true,
      retry: false,
      meta: {
        errorMessage: "Error checking for S3 files",
      },
    }
  );

  return {
    data,
    error,
    refetch,
    isLoading,
  };
}
