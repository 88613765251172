import { useEffect, useContext, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Context from "../../../../Context";
import EditIcon from "@mui/icons-material/Edit";

// styled components
import { TableInnerContainer } from "../../Components/Styled";

export default function EmployeesTable({
  rows,
  loading,
}: {
  rows: any[];
  loading: boolean;
}) {
  const navigate = useNavigate();
  const {
    selectedEmployees,
    setSelectedEmployees,
    selectionModel,
    setSelectionModel,
    lastPage,
    setLastPage,
  } = useContext(Context);
  const [currentPage, setCurrentPage] = useState<number>(0);
  console.log("ROWS", rows)
  
  const columns: GridColDef[] = [
    {
      field: "view",
      headerName: "",
      width: 65,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => (
        <EditIcon
          sx={{cursor: "pointer"}}
          color="primary"
          onClick={() => {
            setLastPage(currentPage);
            navigate(`/roster/employees/${params.row.Momentum_id}`);
          }}
        />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      width: 160,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          <div>{params.row.Name ? params.row.Name : "-"}</div>
          <div className="pending-update">
            {params.row.pending_update ? "Pending Update" : ""}
          </div>
        </div>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      width: 225,
      renderCell: (params: GridRenderCellParams) =>
        params.row.Email ? params.row.Email : "-",
      // align: "center",
    },
    {
      field: "Job_Title",
      headerName: "Job Title",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) =>
        params.row.Job_Title ? params.row.Job_Title : "-",
    },
    {
      field: "team_name",
      headerName: "Team",
      width: 110,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) =>
        params.row.team_name ? params.row.team_name : "-",
    },
    {
      field: "Office_Code",
      headerName: "Market",
      width: 210,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) =>
        params.row.Office_Code ? params.row.Office_Code : "-",
    },
  ];

  const buildSelectedEmployees = (idList: any) => {
    console.log("id list", idList)
    const array = rows.filter((row) => {
      return idList.includes(row.Momentum_id);
    });
    selectedEmployees.forEach((emp) => {
      if(idList.includes(emp.Momentum_id)) {
        if(!array.find((arrItem) => arrItem.Momentum_id === emp.Momentum_id))
        array.push(emp)
      }
    })
    setSelectedEmployees(array);
  };

  useEffect(() => {
    setSelectionModel(selectedEmployees.map((emp) => {
      return emp.Momentum_id
    }))
  },[])

  useEffect(() => {
    console.log("selection model", selectionModel)
  },[selectionModel])

  return (
    <TableInnerContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: lastPage || 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        checkboxSelection
        // hideFooter
        getRowId={(row) => row.Momentum_id}
        // @ts-ignore
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(idList) => {
          console.log("ID LIST", idList);
          buildSelectedEmployees(idList);
          setSelectionModel(idList);
        }}
        keepNonExistentRowsSelected
        loading={loading}
        disableRowSelectionOnClick
        onPaginationModelChange={(params) => {
            console.log("params", params);
            setCurrentPage(params.page);
          }
        }
      
        
      />
    </TableInnerContainer>
  );
}
