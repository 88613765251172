import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CollapsibleCard({
  title,
  children,
  open=false,
  setOpen,
  clickToClose=true,
  onClick,
  headerComponent,
}: {
  title: string;
  children: React.ReactNode;
  open?: boolean;
  setOpen?: (arg: boolean) => void;
  clickToClose?: boolean;
  onClick?: () => void;
  headerComponent?: React.ReactNode;
}) {

  const handleChange = () => {
    setOpen && setOpen(!open);
  }

  return (
    <div>
      <Accordion
        expanded={open}
        onChange={() => {
          onClick && onClick();
          clickToClose && handleChange();
        }}
        sx={{
          border: "none",
          "& .MuiAccordionSummary-root": {
            padding: "0px 0px 0px 0px",
            minHeight: "0px",
            backgroundColor: open ? "rgba(255, 255, 255, .05)" : "#fff",
            marginLeft: 0,
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px 0px 0px 0px",
          },
          "& .MuiAccordionDetails-root": {
            padding: "0px 0px 0px 0px",
          },
          "& MuiPaper-root-MuiAccordion-root": {
            border: "none",
          },
        }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          {headerComponent ? headerComponent : <Typography>{title}</Typography>}
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}