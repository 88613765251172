import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import DragDropFile from "./DragDropFile";
import FileViewer from "./FileViewer";
import FileError from "./FileError";
import ProcessingStatus from "./ProcessingStatus";
import UploadTool from "./UploadTool";
import FilesTable from "./FilesTable";
import { useFileQuery, useS3 } from "./hooks";
import { COLORS } from "../../Theme";
import { Container } from "../../GlobalComponents/Styled";
import { useGetFiles } from "./hooks";
import Skeleton from "@mui/material/Skeleton";

// get midnight of today in EST
  const prefixDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  // convert to ISO string
  const prefixString = prefixDate.toLocaleDateString().replace(/\//g, "_");

  console.log("prefix string", prefixString)

export default function COADFileDrop() {
  // store file
  const [file, setFile] = useState<any>(null);
  // store file type errors
  const [fileError, setFileError] = useState<string | null>(null);
  const { data: allFiles, error, isLoading, refetch: refetchAllFiles } = useGetFiles();
  const {
    data: todayFilesData,
    error: todayFilesError,
    isLoading: todayFilesLoading,
    refetch: todayFilesRefetch,
  } = useGetFiles({prefix: prefixString as any, queryKey: "checkTodayFiles" as any});

  const todayFiles: any[] = todayFilesData as any;
  console.log("today file", todayFiles)

  return (
    <Wrapper>
      <Container>
        <Content>
          <div id="left-container">
            <p id="title">Upload COAD File</p>
            {/* <Skeleton variant="rounded" width={300} height={250} /> */}
            {todayFilesLoading ? (
              <Skeleton variant="rounded" width={285} height={250} />
            ) : (
              <DragDropFile
                setFile={setFile}
                setFileError={setFileError}
                disabled={todayFiles?.length > 0}
              />
            )}
            <div id="fileContainer">
              {/* <ProcessingStatus /> */}
            </div>
          </div>
          <FilesTable
            title="Last Uploaded File"
            files={
              todayFiles && todayFiles.length > 0 ? [todayFiles[0]] :
              !allFiles ? [] : [(allFiles as [])[(allFiles as []).length - 1]]
            }
          />
        </Content>
        <BottomContent>
          <UploadTool
            file={file}
            setFile={setFile}
            fileError={fileError}
            setFileError={setFileError}
            onUploadComplete={() => {
              refetchAllFiles();
              todayFilesRefetch();
            }}
          />
        </BottomContent>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
  align-self: flex-start;
  margin-top: 40px;
  margin-right: 40px;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #585858;
  overflowX: scroll;
  
  #left-container {
    width: 30%;
  }
  
  p {
    color: ${COLORS.PRIMARY};
  }
`;

const BottomContent = styled.div`
  width: 100%;
  height: 300px;
`;