import { useState, useRef } from "react";
import styled from "@emotion/styled";
import { ReactComponent as CloudIcon } from "../../assets/CloudIcon.svg";
import { Button, AnimatedCheckbox } from "../../GlobalComponents";

const allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
    "application/vnd.ms-excel.sheet.binary.macroenabled.12",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.template.macroenabled.12",
    "application/vnd.ms-excel.addin.macroenabled.12",
]

export default function COADFileDrop({ setFile, setFileError, disabled }: { setFile: any, setFileError: any, disabled: boolean }) {
  // drag state
  const [dragActive, setDragActive] = useState<boolean>(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      if(!disabled) {
        setDragActive(true);
      }
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    //@ts-ignore
    !disabled && inputRef.current.click();
  };

  const handleFiles = (files: any) => {
    setFile(null);
    console.log("files", files);
    const newFile = files[0];
    if (!allowedFileTypes.includes(newFile.type)) {
        setFileError("File type not allowed");
        return;
    }
    setFileError(null);
    setFile(files[0]);
  };

  return (
    <Form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
        <input
            ref={inputRef}
            type="file"
            disabled={disabled}
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
        />
        <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={disabled ? "disabled" : dragActive ? "drag-active" : ""}
        >
            <div>
              {
                disabled ?
                <AnimatedCheckbox justAnimation/>
                :
                <CloudIcon />
                }

              {
                disabled ?
                <p className="success">A file has successfully been uploaded within the last 24 hours.</p>
                :
                <div>
                  <p>Drag & Drop File Here<br/>-OR-</p>
                  <Button onClick={onButtonClick}>
                    Browse Files
                  </Button>
                </div>
              }
            </div>
        </label>
        {dragActive && (
            <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            />
        )}
    </Form>
  );
}

const Form = styled.form`
  height: 250px;
  width: 285px;
  text-align: center;
  position: relative;

  #input-file-upload {
    display: none;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #33ccff;
    background-color: #f6faff;
    cursor: pointer;
  }

  #label-file-upload.drag-active {
    background-color: #ffffff;
  }

  #label-file-upload.disabled {
    background-color: #fff;
    border-color: #fff;
  }

  .upload-button {
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .success {
    color: #a4edc3;
    font-weight: 600;
  }
`;

// *{ box-sizing: border-box; user-select: none; }
// html, body{ margin: 0; height: 100%; }
// body{
//   display: flex;
//   background: #212121;
//   justify-content: center;
//   align-items: center;
//   font: 24px/1.4 "RobotoDraft", sans-serif;
//   color: #fff;
// }

// section{
//   display: flex;
//   flex-direction: column;
//   padding: 0.5em;
// }

// h1{
//   font-weight: 400;
//   font-size: 2em;
//   cursor: default;
//   margin: 0 0 .5em 0;
// }

// input[type='checkbox']{ height: 0; width: 0; }

// input[type='checkbox'] + label{
//   position: relative;
//   display: flex;
//   margin: .6em 0;
//   align-items: center;
//   color: #9e9e9e;
//   transition: color 250ms cubic-bezier(.4,.0,.23,1);
// }
// input[type='checkbox'] + label > ins{
//   position: absolute;
//   display: block;
//   bottom: 0;
//   left: 2em;
//   height: 0;
//   width: 100%;
//   overflow: hidden;
//   text-decoration: none;
//   transition: height 300ms cubic-bezier(.4,.0,.23,1);
// }
// input[type='checkbox'] + label > ins > i{
//   position: absolute;
//   bottom: 0;
//   font-style: normal;
//   color: #4FC3F7;
// }
// input[type='checkbox'] + label > span{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-right: 1em;
//   width: 1em;
//   height: 1em;
//   background: transparent;
//   border: 2px solid #9E9E9E;
//   border-radius: 2px;
//   cursor: pointer;  
//   transition: all 250ms cubic-bezier(.4,.0,.23,1);
// }

// input[type='checkbox'] + label:hover, input[type='checkbox']:focus + label{
//   color: #fff;
// }
// input[type='checkbox'] + label:hover > span, input[type='checkbox']:focus + label > span{
//   background: rgba(255,255,255,.1);
// }
// input[type='checkbox']:checked + label > ins{ height: 100%; }

// input[type='checkbox']:checked + label > span{
//   border: .5em solid #FFEB3B;
//   animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1);
// }
// input[type='checkbox']:checked + label > span:before{
//   content: "";
//   position: absolute;
//   top: .6em;
//   left: .2em;
//   border-right: 3px solid transparent;
//   border-bottom: 3px solid transparent;
//   transform: rotate(45deg);
//   transform-origin: 0% 100%;
//   animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards;
// }

// @keyframes shrink-bounce{
//   0%{
//     transform: scale(1);
//   }
//   33%{    
//     transform: scale(.85);
//   }
//   100%{
//     transform: scale(1);    
//   }
// }
// @keyframes checkbox-check{
//   0%{
//     width: 0;
//     height: 0;
//     border-color: #212121;
//     transform: translate3d(0,0,0) rotate(45deg);
//   }
//   33%{
//     width: .2em;
//     height: 0;
//     transform: translate3d(0,0,0) rotate(45deg);
//   }
//   100%{    
//     width: .2em;
//     height: .5em;    
//     border-color: #212121;
//     transform: translate3d(0,-.5em,0) rotate(45deg);
//   }
// }