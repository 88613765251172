import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchEmployees, fetchOptions, fetchTeamLeads } from "../Views/api";
import { Autocomplete, TextField } from "@mui/material";
import { useDebounce } from "use-debounce";
import Input from "./Input";
import styled from "@emotion/styled";
import { ClipLoader } from "react-spinners";
import { COLORS } from "../Theme";

const AsyncAutoComplete = ({onSelect, placeholder, width=210, height=40}: AsyncAutoCompleteProps) => {
    const queryClient = useQueryClient();
    const [searchValue, setSearchValue] = useState<string>("");
    const [debouncedSearchValue] = useDebounce(searchValue, 1000);
    const [resultsOpen, setResultsOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<Employee | null>(null);

    const {
      data: employeesData,
      error: employeesError,
      isLoading: employeesLoading,
      isFetching: employeesFetching,
      refetch: employeesRefetch,
    } = useQuery({
      queryKey: [
        "employees_auto_complete",
        debouncedSearchValue,
      ],
      queryFn: () => fetchEmployees("name", debouncedSearchValue),
      enabled: !!debouncedSearchValue,
      retry: false,
    });

    useEffect(() => {
        if(employeesData && employeesData?.values?.length > 0) {
            setResultsOpen(true);
        } else {
            setResultsOpen(false);
        }
    }, [employeesData]);

    const onOpen = () => {
        if(employeesData && employeesData?.values?.length > 0) {
            setResultsOpen(true);
        }
    }

    const onClose = () => {
        console.log("on close")
        setResultsOpen(false);
    }

    const onItemSelect = (employee: Employee) => {
        setSelectedValue(employee);
        onSelect(employee);
        setResultsOpen(false);
    };

    return (
      <Wrapper style={{width: width, height: height}}>
        <div onClick={onOpen}>
            <Input
                height={height}
                width={width}
                value={selectedValue?.Name || searchValue}
                placeholder={placeholder || "Search"}
                onChange={(text) => {
                    setSelectedValue(null);
                    setSearchValue(text);
                }}
            />
        </div>
        {resultsOpen && (
            <ResultsContainer
                // @ts-ignore
                resultsOpen={resultsOpen}
                style={{width: width - 22}}
            >
                {employeesData?.values?.map((emp: Employee) => (
                <ResultRow key={emp.Momentum_id} onClick={() => onItemSelect(emp)}>{emp.Name}</ResultRow>
                ))}
            </ResultsContainer>
        )}
        {resultsOpen &&
            <ResultsBackground onClick={onClose}/>
        }
        {employeesFetching && (
          <LoaderContainer>
            <ClipLoader color={COLORS.PRIMARY} size={15} />
          </LoaderContainer>
        )}
      </Wrapper>
    );
}

export default AsyncAutoComplete;

type AsyncAutoCompleteProps = {
    onSelect: (employee: Employee) => void;
    placeholder?: string;
    width?: number;
    height?: number;
}

const Wrapper = styled.div`
    position: relative;
`

const ResultsBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100em;
    width: 100vw;
    z-index: 99;
    // background-color: red;
`

const ResultsContainer = styled.div`
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid ${COLORS.GRAY};
  border-radius: 5px;
  padding: 10px;
  z-index: 100;
  overflow: hidden;

  /* Apply animation styles when resultsOpen is true */
  ${(props) =>
    // @ts-ignore
    props.resultsOpen &&
    `
      animation: expandAnimation 0.3s ease;
      animation-fill-mode: forwards;
  `}

  @keyframes expandAnimation {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 200px; /* Set a reasonable maximum height for the content */
      opacity: 1;
    }
  }
`;

const ResultRow = styled.div`
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    &:hover {
        background-color: ${COLORS.LIGHT_GRAY};
    }
`

const LoaderContainer = styled.div`
    position: absolute;
    top: 22%;
    left: 85%;
    right: 100%;
    background-color: white;
`