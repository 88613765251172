import styled from "@emotion/styled";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { COLORS } from "../../../Theme";
import { Button } from "../../../GlobalComponents";
import { TableInnerContainer } from "../Components/Styled";
import { useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";


export default function TeamsTable({
    rows,
    loading,
    onSelect,
    onEdit,
}: {
    rows: any[];
    loading: boolean;
    onSelect: (arg: number) => void;
    onEdit: (arg: number) => void;
}) {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "view",
      headerName: "",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => (
        <LaunchIcon
          sx={{ cursor: "pointer" }}
          color="primary"
          onClick={() => {
            navigate(`/roster/teams/${params.row.id}`);
          }}
        />
      ),
    },
    { field: "team_name", headerName: "Team", width: 250 },
    { field: "office_code", headerName: "Market", width: 250 },
    { field: "team_leader_name", headerName: "Team Lead", width: 250 },
    { field: "number_of_team_members", headerName: "Members on Team", width: 250 },
  ];

  return (
    <TableInnerContainer>
      <DataGrid
        onRowClick={(params) => {
          navigate(`/roster/teams/${params.row.id}`);
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        // hideFooter
        loading={loading}
      />
    </TableInnerContainer>
  );
}