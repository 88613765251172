import styled from "styled-components";
import { COLORS } from "../../Theme";

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: #585858;

  #left-container {
    width: 30%;
  }

  #fileContainer {
  }
  p {
    color: #d5d5d5;
  }
  #title {
    color: ${COLORS.PRIMARY};
    font-family: "Gotham Bold";
  }
  #header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
  }
  #header-tools-container {
    display: flex;
    width: 50%;
    justify-content: space-between;
  }
  #table-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #table-header {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
  #table-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
  }
`;

export default Container;
