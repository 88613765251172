import axios from 'axios';

const fetchEmployees = async (filterOpt?: string | null, filterValue?: string | null) => {
  console.log("FILTER OPT", filterOpt)

    const employeesEnpoint = `/roster`;
    let body: FetchEmployeesBody = { roster_type: "sales" }

    if(filterOpt && filterValue) {
      Object.assign(body, { filter_type: filterOpt });
      Object.assign(body, { filter_value: filterValue });
    }

    console.log("body", body)
    const response = await axios.post(employeesEnpoint, body)
    .then(function ({ data }: { data: FetchEmployeesResponse }) {return data});

    return response;
};


const fetchOptions = async () => {
  const fetchOptionsEndpoint = `/get_options`;

  const response = await axios.get(fetchOptionsEndpoint)
  .then(function ({ data }: { data: FetchOptionsResponse }) {return data});
  console.log("response")

  // replace any null department_grouping values with "Other"
  // for sorting purposes
  const newDeptGroupings = response?.departments_ui?.map((dept: any) => {
    const newDept = { ...dept };
    if(!newDept.department_grouping) {
      newDept.department_grouping = "Other";
    }
    return newDept;
  });
  
  // sort department_grouping values in reverse order
  const sortedDepartmentGroupings = newDeptGroupings.sort((a, b) => {
    // Move null values to the end of the array
    if (a.department_grouping === null) return 1;
    if (b.department_grouping === null) return -1;

    // Compare department_grouping values in reverse order
    if (a.department_grouping > b.department_grouping) return -1;
    if (a.department_grouping < b.department_grouping) return 1;
    return 0;
  });

  const res = {
      teams: response?.teams,
      markets: response?.markets,
      markets_filter: response?.markets_filter,
      departments: response?.departments,
      active_status: response?.active_status,
      department_groupings: sortedDepartmentGroupings,
  }

  return res;
};

const postUpdateEmployeesRequest = async (body: any) => {
  const updateEmployeesEndpoint = `/update`;
  console.log("body", body);

  const response = await axios
    .post(updateEmployeesEndpoint, body)
    .then(({ data }) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(data);
        }, 3000);
      });
    });

  return response;
};

const fetchChangeOrder = async (changeId: string) => {
  const fetchChangeOrderEnpoint = `/get_pending?pending_id=${changeId}`;
  const response = await axios.get(
    fetchChangeOrderEnpoint
  ).then(function ({ data }: { data: FetchChangeOrderResponse }) {return data});
  return response;
};

type ConfirmChangeOrderBody = {
  pending_id: number,
  actioned_by: string,
}

const confirmChangeOrder = async (body: ConfirmChangeOrderBody) => {
  console.log("bldy", body)
  const confirmChangeOrderEndpoint = `/update_pending`;
  const response = await axios.post(
    confirmChangeOrderEndpoint,
    body
  )
  return response
};

const fetchTeams = async () => {
  const teamsEnpoint = `/roster`;
  const body = {
    roster_type: "sales_teams",
  }

  const response = await axios
    .post(teamsEnpoint, body)
    .then(function ({ data }: { data: FetchTeamsResponse }) {
      const withLabels = data.values.map((team) => {
        const newTeam = { ...team };
        newTeam.label = team.team_name;
        newTeam.value = team.id;
        newTeam.key = team.id.toString();
        return newTeam;
      });
      return withLabels;
    });

  return response;
};

const fetchTeamById = async (teamId: string | undefined) => {
  if(!teamId) return;

  const teamsEnpoint = `/roster`;
  const body = {
    roster_type: "sales_teams",
  };

  const response = await axios
    .post(teamsEnpoint, body)
    .then(function ({ data }: { data: FetchTeamsResponse }) {
      return data.values.find((team) => team.id === parseInt(teamId));
    });

  return response;
};


const fetchEmployeesByTeamId = async (teamId: string | undefined) => {
  if(!teamId) return;

  const employeesEnpoint = `/roster`;
  let body: FetchEmployeesBody = { roster_type: "sales" };

  console.log("body", body);
  const response = await axios
    .post(employeesEnpoint, body)
    .then(function ({ data }: { data: FetchEmployeesResponse }) {
      const newData = data.values.map((employee, idx) => {
        const newEmployee: any = { ...employee };
        if(idx < 10) {
          newEmployee.team_id = teamId;
          newEmployee.team_name = "Team 1";
          newEmployee.team_lead = "Papa Solar";
        } else if(idx < 20) {
          newEmployee.team_id = 11;
          newEmployee.team_name = "Team 2";
          newEmployee.team_lead = "Papa Solar";
        } else if(idx < 30) {
          newEmployee.team_id = 13;
          newEmployee.team_name = "Team 3";
          newEmployee.team_lead = "Papa Solar";
        }
        return newEmployee;
      });
      console.log("NEW DATA", newData)
      const filtered = newData.filter((employee) => employee.team_id === parseInt(teamId));
      console.log("FILTERED", filtered)
      return {
        values: filtered,
      };
    });

  return response;
};

const fakeTeamLeaders = [
  {
    id: 1,
    label: "Papa Solar",
    team: "Team 1",
    name: "Papa Solar",
  },
  {
    id: 2,
    label: "John Smith",
    team: "Team 2",
    name: "John Smith",
  },
  {
    id: 3,
    label: "Jane Doe",
    team: "Team 3",
    name: "Jane Doe",
  }
];

const fetchTeamLeads = async () => {
  return {
    values: fakeTeamLeaders
  };
};

const postTeam = async (queryKey: any) => {
  console.log("QUERY KEY", queryKey);
  const route = queryKey.route;
  console.log("ROUTE", route);
  const body = queryKey.body;

  const response = await axios
    .post(route, body)
    .then(({ data }) => {
      return data
    }
  );

  return response;
};

export { 
  fetchEmployees,
  fetchOptions,
  fetchChangeOrder,
  confirmChangeOrder,
  postUpdateEmployeesRequest,
  fetchTeams,
  fetchEmployeesByTeamId,
  fetchTeamLeads,
  fetchTeamById,
  postTeam,
};

