import styled from "@emotion/styled";
import { COLORS } from "../Theme";

const ButtonV2 = ({
  disabled,
  label,
  Icon,
  onClick,
}: {
  disabled?: boolean;
  label: string;
  Icon: any;
  onClick: () => void;
}) => {
  const StyledButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    border: 1px solid ${disabled ? "#D5D5D5" : COLORS.PRIMARY};
    border-radius: 5px;
    height: 40px;
    padding-left: 10px;
    background-color: ${disabled ? "#D5D5D5" : COLORS.PRIMARY};
  `;

  return (
    <StyledButton onClick={() => !disabled && onClick()}>
      <Icon style={{ color: "#fff" }} width={25}/>
      <p style={{ marginLeft: 10, color: "#fff" }}>{label}</p>
    </StyledButton>
  );
};

export default ButtonV2;
