import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Input } from "../../GlobalComponents";
import { useGetFiles } from "./hooks";
import { limitString, removePrefix } from "../../Util";
import { COLORS } from "../../Theme";
import AWS from "aws-sdk";

const s3 = new AWS.S3();

export default function FilesTable({files, title="Uploaded File(s)"}: {files: any, title?: string}) {

  // const onSearch = (e: any) => {
  //   console.log("search", e.target.value);
  // };

   const downloadFile = (key: string) => {
     const params = {
       Bucket: "waldocoadv2",
       Key: key,
     };
     s3.getObject(params, (err, data) => {
       if (err) {
         console.log(err);
         return;
       }
       const url = URL.createObjectURL(new Blob([data.Body as any]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", key);
       document.body.appendChild(link);
       link.click();
     });
   };


  return (
    <Container>
      {/* <Input 
        placeholder="Search Files"
        onChange={onSearch}
        value={search}
      /> */}
      <p className="header">{title}</p>
      <div style={{ width: "100%" }}>
        <Row>
          <p className="file-name row-header">File Name</p>
          <p className="file-size row-header">File Size</p>
          <p className="date-uploaded row-header">Date Uploaded</p>
          <span className="actions row-header" />
        </Row>
        <div className="files">
          {(files as any)?.map((file: any) => {
            return (
              <Row key={file.Key}>
                <p className="file-name">{limitString(removePrefix(file.Key), 20)}</p>
                <p className="file-size">
                  {(file.Size / (1024 * 1024)).toFixed(2)}MB
                </p>
                <p className="date-uploaded">
                  {file.LastModified.toLocaleDateString()}
                </p>
                <div className="actions">
                  {/* <button style={{ backgroundColor: "#D5D5D5" }}>View</button> */}
                  <button
                    onClick={() => {
                      downloadFile(file.Key)
                    }}
                  >Download</button>
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 60%;

  .header {
    color: #585858;
    width: 100%;
    text-align: left;
    font-family: "Gotham Bold";
    font-size: 0.9em;
  }

  button {
    width: 90px;
    height: 25px;
    border-radius: 5px;
    background-color: ${COLORS.PRIMARY};
    color: #fff;
    font-family: "Gotham Bold";
    font-size: 0.9em;
    border: none;
    cursor: pointer;
    &:hover {
        background-color: ${COLORS.PRIMARY_HOVER};
    }
    margin: 0px 5px; 
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;

  p {
    color: #000;
  }

  .row-header {
    color: #b2b2b2;
  }

  .file-name {
    width: 30%;
  }

  .file-size {
    width: 20%;
  }

  .date-uploaded {
    width: 20%;
    text-align: center;
  }

  .actions {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
  }
`;
