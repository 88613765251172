const limitString = (string: string, maxLength: number) => {
  if (string.length <= maxLength) {
    return string;
  } else {
    const fileType = string.match(/\.[a-zA-Z0-9]+$/); // Extracts the file type if it exists
    if (fileType) {
      const shortenedString = string.slice(0, maxLength - fileType[0].length);
      return shortenedString + ".." + fileType[0];
    } else {
      return string.slice(0, maxLength) + "...";
    }
  }
};

export default limitString;
