import AWS from "aws-sdk";

const s3 = new AWS.S3();

const getFiles = async (files: FileList): Promise<File[]> => {
  const filesArray = [];
  for (let i = 0; i < files.length; i++) {
    filesArray.push(files[i]);
  }
  return filesArray;
};

const fetchFilesFromS3 = async (prefix?: string) => {
  try {
    return new Promise(async (resolve, reject) => {
      s3.listObjectsV2(
        {
          Bucket: "waldocoadv2",
          Prefix: prefix,
        },
        (err, data) => {
          if (err) {
            console.log("---FILE error", err);
            return err;
          }
          console.log("---FILE data", data);
          resolve(data.Contents);
        }
      );
    });
  } catch (err) {
    console.log("error", err);
    return err;
  }
};

export { getFiles, fetchFilesFromS3 }