import axios from 'axios';
import './App.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme, createStyles, ThemeProvider } from '@mui/material';
import { COLORS } from './Theme';
import BrowserRouter from './BrowserRouter';

// launch darkly
import { withLDProvider } from "launchdarkly-react-client-sdk";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      networkMode: "online",
      cacheTime: 1000 * 60 * 60 * 24,
    },
    mutations: {
      networkMode: "online",
    },
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    grey: {
      
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 34,
          padding: "0 4px 7.5px 5px",
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-7px",
          fontSize: "14px"
        }
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        }
      }
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter/>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

// Update the export default to use your environment-specific client ID and a sample context:
export default withLDProvider({
  clientSideID: '64b75253bcee0714281e7875',
  context: {
    "kind": "user",
    "key": "example_user",
    "name": "Example user",
    "email": "User@example.com"
  }
})(App);